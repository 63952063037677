import * as React from 'react';

import {AppBar, AppBarSection, AppBarSpacer} from "@progress/kendo-react-layout";
import {DateTime} from "luxon";


const AppbarBottom = (props) => (

    <AppBar position={'bottom'} themeColor={'dark'}>
        {/*<AppBarSection>*/}
        {/*    <ul>*/}
        {/*        <li><span>Privacy</span></li>*/}
        {/*        <li><span>About Us</span></li>*/}
        {/*        <li><span>Contacts</span></li>*/}
        {/*    </ul>*/}
        {/*</AppBarSection>*/}

        <AppBarSpacer/>

        {/*<CardSubtitle>posted on {DateTime.fromISO(issuedDatetime).toLocaleString(DateTime.DATE_FULL)} -*/}
        <AppBarSection>
            <span>
            <ul>
                <li style={{margin: "0px 5px"}}>&copy; {DateTime.local().year} enis brulic</li>
                <li style={{margin: "0px 5px"}}>|</li>
                <li style={{margin: "0px 5px"}}>licensed under a </li>
            </ul>
            </span>
            <span>
                <button className="k-button k-button-clear">
                    <a href="https://creativecommons.org/licenses/by-sa/4.0/" target='_blank' rel="noreferrer"
                    >CC BY-SA 4.0</a>
                    {/*<a rel="license" href="http://creativecommons.org/licenses/by-sa/4.0/" target='_blank' rel="noreferrer">*/}
                    {/*    <img alt="Creative Commons Attribution-ShareAlike 4.0 International License"*/}
                    {/*         src="https://i.creativecommons.org/l/by-sa/4.0/88x31.png"/>*/}
                    {/*</a>*/}
                    {/*<a rel="license" href="http://creativecommons.org/licenses/by-sa/4.0/">Creative Commons Attribution-ShareAlike 4.0 International License</a>*/}
                </button>
            </span>
        </AppBarSection>
    </AppBar>
)

export default AppbarBottom;