import * as React from 'react';
import {Route, Routes} from 'react-router-dom';
import LandingPage from "../../components/landingPage/LandingPage";
import PostDetail from "../../components/postdetail/PostDetail";
import TopPosts from "../../components/landingPage/TopPosts";

export default function Routing() {

    function getRoutes() {
        return (
            // <BrowserRouter>
            <Routes>
                <Route path="/" element={<LandingPage/>}/>
                <Route path="/post/:pathParamRefId" element={<PostDetail/>}/>
                <Route path="/topposts" element={<TopPosts/>}/>
            </Routes>
            // </BrowserRouter>
        )
    }

    return (
        <React.Fragment>
            {getRoutes()}
        </React.Fragment>
    );
}
