import axios from 'axios';
import {Config} from "../Config";

const instance = axios.create({baseURL: Config.api_url});

instance.defaults.headers['Content-Type'] = 'application/json';

instance.interceptors.request.use(request => {
    return request;
}, error => {
    return Promise.reject(error);
});

instance.interceptors.response.use( response => {
    return response;
}, error => {
    return Promise.reject(error);
});

export default instance;