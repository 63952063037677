import * as React from 'react';
import {useNavigate} from "react-router";

export default function Logo() {

    const navigate = useNavigate();

    const onLogoClick = () => {
        navigate('/');
    }

    return (
        <div onClick={onLogoClick}>
            <div className="top-title">enis-brulic.com</div>
        </div>
    );
}
