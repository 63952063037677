import thunkMiddleware from 'redux-thunk';
import {combineReducers, configureStore, PreloadedState} from "@reduxjs/toolkit";
import {TypedUseSelectorHook, useDispatch, useSelector} from "react-redux";
import {postReducer} from "./postSlice";

const reducer = combineReducers({
    post: postReducer,
})
export const store = configureStore({
    reducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunkMiddleware)
})

export default function configureAppStore(preloadedState?: PreloadedState<RootState>) {
    return configureStore({
        reducer,
        preloadedState
    })
}

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
export type AppStore = ReturnType<typeof configureAppStore>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export const usePostSelector: TypedUseSelectorHook<RootState> = useSelector;

export const usePostDispatch = () => useDispatch<AppDispatch>()
