import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {DateTime} from "luxon";

class PostMetaInfo extends PureComponent {

    static propTypes = {
        issued: PropTypes.string.isRequired,
        updated: PropTypes.string,
        showTotalHits: PropTypes.bool,
        totalHits: PropTypes.number,
        likes: PropTypes.number,
        author: PropTypes.string.isRequired,
        styleName: PropTypes.string
    }

    render() {
        const {issued, updated, showTotalHits, totalHits, likes, author, styleName} = this.props;
        const issuedDate = DateTime.fromISO(issued).toLocaleString(DateTime.DATETIME_MED);
        const updatedDate = updated && DateTime.fromISO(updated).toLocaleString(DateTime.DATETIME_MED);

        return (showTotalHits
                ? (updatedDate ?
                    <span>
                        <span className={styleName}>
                            posted on {issuedDate} - by {author}, updated on {updatedDate}, {totalHits} hits, {likes} likes
                        </span>
                    </span>
                    :
                        <span className={styleName}>
                            posted on {issuedDate} - by {author}, {totalHits} hits, {likes} likes
                        </span>
                )
                : (updatedDate ?
                        <span className={styleName}>
                            posted on {issuedDate} - by {author}, updated on {updatedDate}
                        </span>
                        :
                        <span className={styleName}>
                            posted on {issuedDate} - by {author}
                        </span>
                )
        )
    }
}

export default PostMetaInfo;