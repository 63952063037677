import React from 'react';
import AppbarTop from "../../navigation/appbarTop/AppbarTop";
import AppbarBottom from "../../navigation/appbarBottom/AppbarBottom";

class Layout extends React.Component {
    render() {
        return (
            <React.Fragment>
                <AppbarTop/>
                <div className="pageContent">
                    {this.props.children}
                </div>
                <AppbarBottom/>
            </React.Fragment>
        );
    }
}

export default Layout