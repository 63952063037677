import * as React from 'react';

import {AppBar, AppBarSection, AppBarSpacer} from "@progress/kendo-react-layout";
import Logo from "../../components/logo/Logo";
import {Link} from "react-router-dom";

import gitInfo from '../../_gitInfo';
import {usePostDispatch} from "../../redux/store";
import {getPostDetailThunk} from "../../redux/postSlice";

export default function AppbarTop() {

    const postDispatch = usePostDispatch();

    const parseRefId = (href) => {
        return href.split('/')[4];
    }

    const onChangePost = (event) => {
        const selectedRefId = parseRefId(event.nativeEvent.target.href);
        postDispatch(getPostDetailThunk({refId: selectedRefId}))
    }

    return (
        <>
            <AppBar className="appbarTopGitInfo">
                <div className="git-info">V1.0-{gitInfo.shortRevision ? gitInfo.shortRevision : 'c8d4c2f'}</div>
            </AppBar>
            <AppBar className="appbarTop">
                <AppBarSection>
                    <Logo/>
                </AppBarSection>
                <AppBarSpacer/>
                <AppBarSection className="appbar-section-top">
                    <div className="top-menu">
                        <ul>
                            <li>
                            <span>
                                <Link to={`/post/about`} onClick={onChangePost} className="k-button k-button-clear"
                                      style={{color: "white"}}>about</Link>
                            </span>
                            </li>
                            <li>
                            <span>
                                <Link to="/post/contact" onClick={onChangePost} className="k-button k-button-clear"
                                      style={{color: "white"}}>contact</Link>
                            </span>
                            </li>
                            <li style={{alignSelf: "center"}}>
                                <button className="k-button k-button-clear" style={{color: "white"}}>
                                    <a href="https://twitter.com/enisbrulic" target='_blank' rel="noreferrer"
                                       className="actions k-button k-button-clear" style={{color: "white"}}>
                                        <span className="k-icon k-i-twitter"/>
                                    </a>
                                </button>
                            </li>
                        </ul>
                    </div>
                </AppBarSection>
            </AppBar>

        </>
    );
}
