import React, {Component} from 'react';
import './App.scss';
import Layout from "./components/layout/Layout";
import Routing from "./container/routing/Routing";

class App extends Component {

    render() {
        return (
            <div>
                <Layout>
                    <Routing/>
                </Layout>
            </div>
        );
    }
}

export default App;
