import * as React from "react";
import {useEffect} from "react";
// @ts-ignore
import ReactMarkdown from "react-markdown";
import CodeBlock from "./CodeBlock";
import {ListView} from "@progress/kendo-react-listview";
import {Link, useParams} from "react-router-dom";
import PostMetaInfo from "../../container/postmetainfo/PostMetaInfo";
import {CardActions} from "@progress/kendo-react-layout";
import {usePostDispatch, usePostSelector} from "../../redux/store";
import {PostDetailModel} from "../../model/PostDetailModel";
import {getPostDetailThunk, postLikePostThunk} from "../../redux/postSlice";

const maxLength = 20;

export default function PostDetail() {

    const postDispatch = usePostDispatch();
    const {pathParamRefId} = useParams();
    const postDetail = usePostSelector((state) => state.post.postDetail) as PostDetailModel;

    const [likeActivated, setLikeActivated] = React.useState<Boolean>(false);
    const [sendLike, setSendLike] = React.useState<Boolean>(false);

    const [width, setWidth] = React.useState<number>(0);
    const [height, setHeight] = React.useState<number>(0);

    const handleResize = () => {
        //force rerender to update ListView size
        // this.setState({width: window.innerWidth, height: window.innerHeight});
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        if (pathParamRefId) {
            postDispatch(getPostDetailThunk({refId: pathParamRefId}))
        }
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const onChangePost = (event) => {
        const selectedRefId = parseRefId(event.nativeEvent.target.href);
        if (sendLike) {
            setLikeActivated(!likeActivated);
            setSendLike(false);
        }
        postDispatch(getPostDetailThunk({refId: selectedRefId}))
    }

    const parseRefId = (href) => {
        return href.split('/')[4];
    }

    const truncateText = function (str, length, ending) {
        if (length == null) {
            length = 15;
        }
        if (ending == null) {
            ending = '...';
        }
        if (str != null && str.length > length) {
            return str.substring(0, length - ending.length) + ending;
        } else {
            return str;
        }
    };

    const header = () => {
        const prevPost = postDetail && postDetail.post && postDetail.post.prevPost;
        const nextPost = postDetail && postDetail.post && postDetail.post.nextPost;
        return (
            <CardActions className="postDetail-actions" layout='center'>
                {prevPost && <span className="prevPostLink">
                    <span className="k-icon k-i-arrow-left"/>
                    <Link to={`/post/${prevPost}`}
                          onClick={onChangePost}>{`${truncateText(prevPost, maxLength, null)}`}</Link>
                </span>}
                <span className="bullseyeLink">
                    <Link to="/">start</Link>
                </span>
                {nextPost && <span className="nextPostLink">
                    <Link to={`/post/${nextPost}`}
                          onClick={onChangePost}>{`${truncateText(nextPost, maxLength, null)}`}</Link>
                    <span className="k-icon k-i-arrow-right"/>
                </span>}
            </CardActions>
        );
    }

    const onLike = (event) => {
        const selectedRefId = parseRefId(event.nativeEvent.target.baseURI);
        if (!sendLike) {
            postDispatch(postLikePostThunk({refId: selectedRefId}))
            setLikeActivated(!likeActivated);
            setSendLike(true);
        }
    };

    const renderPostDetail = props => {
        return (
            <React.Fragment>
                <PostMetaInfo styleName="postDetail-issued"
                              issued={postDetail.post.issued}
                              updated={postDetail.post.updated}
                              showTotalHits={true}
                              totalHits={postDetail.post.totalHits}
                              likes={postDetail.post.likes}
                              author={postDetail.post.author}/>
                <div className="postDetail">
                    <ReactMarkdown source={postDetail.content}
                                   renderers={{code: CodeBlock}}/>
                    <hr style={{height: '50'}}/>
                    <div style={{flexFlow: "row", textAlign: "end"}}>
                        <button className="postlike" onClick={onLike}>
                            <span
                                className={likeActivated ? 'k-icon k-i-heart' : 'k-icon k-i-heart-outline'}/>
                            <span style={{padding: "0 5px"}}>this post</span>
                        </button>
                    </div>
                </div>
            </React.Fragment>
        )
    }

    const postDetailList = postDetail && [{postDetail: postDetail}];
    const offsetWindowInnerHeight = 110;
    // if (failFetchPostDetail === true) { //todo
        // this.props.history.push("/");
    // }
    return (
        <ListView className="postdetail-listview"
                  data={postDetailList}
                  item={renderPostDetail}
                  header={header}
                  style={{height: window.innerHeight - offsetWindowInnerHeight, width: "100%"}}
        />
    );
}
