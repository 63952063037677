import * as React from "react";
import {ListView, ListViewEvent, ListViewHeader, ListViewItemProps} from "@progress/kendo-react-listview";
import {Card, CardBody, CardSubtitle, CardTitle} from '@progress/kendo-react-layout';
import {Link} from "react-router-dom";
import PostMetaInfo from "../../container/postmetainfo/PostMetaInfo";
import {usePostDispatch, usePostSelector} from "../../redux/store";
import {
    clearRangePosts,
    disableLoadMorePosts,
    getAvailableMaxPostsThunk,
    getPostRangeThunk, setInitLoadPosts,
    setPostsData,
    setStartIndex
} from "../../redux/postSlice";
import {PostModel} from "../../model/PostModel";
import {useEffect} from "react";
import {useNavigate} from "react-router";
import {useDispatch} from "react-redux";
import { Button } from "@progress/kendo-react-buttons";

export default function LandingPage() {

    const postDispatch = usePostDispatch();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const rangePosts = usePostSelector((state) => state.post.post) as PostModel[];
    const loadingRangePosts = usePostSelector((state) => state.post.loadingRangePosts) as Boolean;
    const postsData = usePostSelector((state) => state.post.postsData) as PostModel[];
    const initLoadPosts = usePostSelector((state) => state.post.initLoadPosts) as Boolean;
    const loadMorePosts = usePostSelector((state) => state.post.loadMorePosts) as Boolean;
    const startIndex = usePostSelector((state) => state.post.startIndex) as number;

    const [width, setWidth] = React.useState<number>(0);
    const [height, setHeight] = React.useState<number>(0);

    const fetchSize = 10;

    const handleResize = () => {
        //force rerender to update ListView size
        // this.setState({width: window.innerWidth, height: window.innerHeight});
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    };

    useEffect(() => {
        window.scrollTo({top: 0, behavior: 'smooth'});
        if (loadMorePosts && !loadingRangePosts && !initLoadPosts) {
            const calculatedLastIndex = startIndex + fetchSize;
            postDispatch(getAvailableMaxPostsThunk());
            postDispatch(getPostRangeThunk({startIndex: startIndex, lastIndex: calculatedLastIndex - 1}));
            dispatch(setStartIndex(calculatedLastIndex));
            dispatch(setInitLoadPosts());
        }

        window.addEventListener('resize', handleResize);
        // // @ts-ignore
        // document.querySelector(".k-listview-content").addEventListener("scroll", scrollHandler);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (rangePosts?.length > 0) {
            dispatch(setPostsData(postsData.concat(rangePosts.slice())))
            dispatch(clearRangePosts())
        }
        if (rangePosts?.length === 0) {
            dispatch(disableLoadMorePosts())
        }
    }, [rangePosts]);

    function onTopPost() {
        navigate('/topposts');
    }

    const header = () => {
        return (
            <ListViewHeader>
                <div className="title">developer blog</div>
                <div className="topPosts">
                    <span>
                        <Button fillMode={"flat"} onClick={onTopPost}>top posts</Button>
                    </span>
                </div>
            </ListViewHeader>
        );
    }

    const scrollHandler = (event: ListViewEvent) => {
        const e = event?.nativeEvent;
        if (
            e?.target.scrollTop + 10 >=
            e?.target.scrollHeight - e?.target.clientHeight
        ) {
            if (loadMorePosts && !loadingRangePosts) {
                const calculatedLastIndex = startIndex + fetchSize;
                postDispatch(getPostRangeThunk({startIndex: startIndex, lastIndex: calculatedLastIndex - 1}));
                dispatch(setStartIndex(calculatedLastIndex));
            }
        }
    };

    const renderPosts = (props: ListViewItemProps) => {
        const issued = props.dataItem && props.dataItem.issued;
        const totalHits = props.dataItem && props.dataItem.totalHits;
        const target = `post/${props.dataItem.refId}`;
        return (
            <Card>
                <CardBody>
                    <CardSubtitle>
                        <PostMetaInfo issued={issued} totalHits={totalHits} author={props.dataItem.author}/>
                    </CardSubtitle>
                    <Link to={target}>
                        <CardTitle>
                            {props.dataItem.title}
                        </CardTitle>
                    </Link>
                    <div className="contentSummary">{props.dataItem.summary}
                        <span style={{fontSize: "1.4em"}}>
                            <Link to={target}>(...) {"{more...}"}</Link>
                        </span>
                    </div>
                </CardBody>
            </Card>
        );
    }

    const offsetWindowInnerHeight = 110;
    return (
        <div style={{display: "flex", flexDirection: "row"}}>
            <ListView
                onScroll={scrollHandler}
                data={postsData}
                item={renderPosts}
                header={header}
                style={{height: window.innerHeight - offsetWindowInnerHeight}}
            />
        </div>
    );
}

