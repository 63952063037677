import {createAsyncThunk, createSlice, PayloadAction, Reducer} from '@reduxjs/toolkit'
import axiosPostService from '../utils/AxiosPostService';
import {AxiosResponse} from "axios";
import {PostModel} from "../model/PostModel";
import {updateObject} from "../utils/utility";

const initialState = {
    post: undefined,
    loadingRangePosts: false,
    initLoadPosts: false,
    loadMorePosts: true,
    postsData: [],
    startIndex: 0,
    availableMaxPosts: 0,
    topPosts: undefined,
    postDetail: undefined,
    failFetchPostDetail: false,
    loading: false
};


const getRangePosts = (startIndex: number, lastIndex: number) => {
    return axiosPostService.get<unknown, AxiosResponse<PostModel>>(
        `/api/post/range/${startIndex}/${lastIndex}`, {})
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        })
};

const getAvailableMaxPosts = () => {
    return axiosPostService.get<unknown, AxiosResponse<number>>(
        '/api/post/count', {})
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        })
};

const getTopPosts = () => {
    return axiosPostService.get<unknown, AxiosResponse<number>>(
        '/api/post/top/10', {})
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        })
};
const getPostDetail = (refId) => {
    return axiosPostService.get<unknown, AxiosResponse<number>>(
        `/api/postdetail/${refId}`, {})
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        })
};

const postLikePost = (refId) => {
    return axiosPostService.post<unknown, AxiosResponse<any>>(
        `/api/post/like/${refId}`, {})
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        })
};

export const getPostRangeThunk = createAsyncThunk(
    'post/get/range',
    async (obj: { startIndex: number, lastIndex: number }, thunkAPI) => {
        return await getRangePosts(obj.startIndex, obj.lastIndex);
    }
)
export const getAvailableMaxPostsThunk = createAsyncThunk(
    'post/get/count',
    async (thunkAPI) => {
        return await getAvailableMaxPosts();
    }
)
export const getTopPostsThunk = createAsyncThunk(
    'post/get/top10',
    async (thunkAPI) => {
        return await getTopPosts();
    }
)
export const getPostDetailThunk = createAsyncThunk(
    'post/get/postdetail',
    async (obj: { refId: string }, thunkAPI) => {
        return await getPostDetail(obj.refId);
    }
)
export const postLikePostThunk = createAsyncThunk(
    'post/post/like',
    async (obj: { refId: string }, thunkAPI) => {
        return await postLikePost(obj.refId);
    }
)

export const postSlice = createSlice({
    name: 'training/get',
    initialState,
    reducers: {
        disableLoadMorePosts: (state, action: PayloadAction) => {
            return updateObject(state, {
                loadMorePosts: false
            })
        },
        setInitLoadPosts: (state, action: PayloadAction) => {
            return updateObject(state, {
                initLoadPosts: true
            })
        },
        setPostsData: (state, action: PayloadAction<PostModel[]>) => {
            return updateObject(state, {
                postsData: action.payload
            })
        },
        setStartIndex: (state, action: PayloadAction<number>) => {
            return updateObject(state, {
                startIndex: action.payload
            })
        },
        clearRangePosts: (state, action: PayloadAction<number>) => {
            return updateObject(state, {
                post: undefined
            })
        },
    },
    extraReducers: (builder) => {
        // Add reducers for additional action types here, and handle loading state as needed
        builder
            // getPostRangeThunk
            .addCase(getPostRangeThunk.pending, (state, action) => {
                return updateObject(state, {loadingRangePosts: true})
            })
            .addCase(getPostRangeThunk.fulfilled, (state, action) => {
                return updateObject(state, {post: action.payload, loadingRangePosts: false});
            })
            .addCase(getPostRangeThunk.rejected, (state, action) => {
                console.log('rejected getPostRangeThunk: ', state, action);
                return updateObject(state, {loadingRangePosts: false})
            })
            // getAvailableMaxPostsThunk
            .addCase(getAvailableMaxPostsThunk.pending, (state, action) => {
                return updateObject(state, {loading: true})
            })
            .addCase(getAvailableMaxPostsThunk.fulfilled, (state, action) => {
                return updateObject(state, {availableMaxPosts: action.payload, loading: false});
            })
            .addCase(getAvailableMaxPostsThunk.rejected, (state, action) => {
                console.log('rejected getAvailableMaxPostsThunk: ', state, action);
                return updateObject(state, {loading: false})
            })
            // getTopPostsThunk
            .addCase(getTopPostsThunk.pending, (state, action) => {
                return updateObject(state, {loading: true})
            })
            .addCase(getTopPostsThunk.fulfilled, (state, action) => {
                return updateObject(state, {topPosts: action.payload, loading: false});
            })
            .addCase(getTopPostsThunk.rejected, (state, action) => {
                console.log('rejected getTopPostsThunk: ', state, action);
                return updateObject(state, {loading: false})
            })
            // getPostDetailThunk
            .addCase(getPostDetailThunk.pending, (state, action) => {
                return updateObject(state, {loading: true})
            })
            .addCase(getPostDetailThunk.fulfilled, (state, action) => {
                return updateObject(state, {postDetail: action.payload, loading: false, failFetchPostDetail: false});
            })
            .addCase(getPostDetailThunk.rejected, (state, action) => {
                console.log('rejected getPostDetailThunk: ', state, action);
                return updateObject(state, {loading: false, failFetchPostDetail: true})
            })
            // postLikePostThunk
            .addCase(postLikePostThunk.pending, (state, action) => {
                return updateObject(state, {loading: true})
            })
            .addCase(postLikePostThunk.fulfilled, (state, action) => {
                return updateObject(state, {loading: false});
            })
            .addCase(postLikePostThunk.rejected, (state, action) => {
                console.log('rejected postLikePostThunk: ', state, action);
                return updateObject(state, {loading: false})
            })

    }
})

export const {
    disableLoadMorePosts,
    setInitLoadPosts,
    setPostsData,
    setStartIndex,
    clearRangePosts
} = postSlice.actions;
export const postReducer: Reducer<typeof initialState> = postSlice.reducer;
