import * as React from "react";
import {Card, CardBody, CardTitle} from "@progress/kendo-react-layout";
import {Link} from "react-router-dom";
import {usePostDispatch, usePostSelector} from "../../redux/store";
import {PostModel} from "../../model/PostModel";
import {getTopPostsThunk} from "../../redux/postSlice";
import {useEffect} from "react";

export default function TopPosts() {

    const postDispatch = usePostDispatch();
    const topPosts = usePostSelector((state) => state.post.topPosts) as PostModel[];

    useEffect(() => {
        postDispatch(getTopPostsThunk());
    }, []);

    const topPostLinks = topPosts && topPosts.map((post) => {
        return <React.Fragment key={post.refId}>
            <Link to={`/post/${post.refId}`}>
                <div className="contentTopPosts">{post.title}</div>
            </Link>
        </React.Fragment>
    });

    return (
        <div className="topPosts">
            <Card>
                <CardBody>
                    <CardTitle style={{color: "#fff"}}>top posts</CardTitle>
                    {topPostLinks}
                </CardBody>
            </Card>
        </div>
    );
}
